import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Augillion from '../style/Augillion.otf';
import { Link } from 'gatsby';

import Helmet from 'react-helmet';
import MaxMichael from './../images/svg/max-michael.svg';

import favicon from '../images/favicon.png';
import Burger from './burger';
import Menu from './menu';
import ImageCircle from './ImageCircle';
import { AnimatePresence } from 'framer-motion';

const Layout = ({ children, path }) => {
  const [open, setOpen] = useState(false);
  const [isHome, setIsHome] = useState(true);
  useEffect(() => {
    setIsHome(path === '/');
  }, [path]);

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='https://use.typekit.net/oec0vbl.css' />
        <link rel='icon' href={favicon} />
        <meta property='og:title' content='Max & Michael' />
        <meta property='og:description' content="We're finally getting married!" />
        <title>Max & Michael</title>
      </Helmet>
      <GlobalStyle />
      <Burger open={open} setOpen={setOpen} />
      <MaroonCircle isHome={isHome} />
      <RedCircle isHome={isHome} />
      <RoseCircle isHome={isHome} />
      <AnimatePresence>{isHome && <ImageCircle isHome={isHome} />}</AnimatePresence>
      <Link to='/'>
        <MaxMichaelStyled $isHome={isHome} />
      </Link>
      <Main>{children}</Main>
      <Menu open={open} setOpen={setOpen} />
      <Footer>
        <p>
          Made with{' '}
          <span role='img' aria-label='heart emoji'>
            ❤️
          </span>
          <span role='img' aria-label='rainbow flag emoji'>
            🏳️‍🌈
          </span>{' '}
          | 2021
        </p>
      </Footer>
    </>
  );
};

const MaxMichaelStyled = styled(MaxMichael)`
  width: 150px;
  height: auto;
  transform: ${({ $isHome }) =>
    $isHome
      ? 'translateX(50%) translateY(40px) scale(1.8)'
      : 'translateX(50vw) translateX(-50%) scale(1)'};
  display: block;
  position: relative;
  margin-top: ${({ $isHome }) => ($isHome ? '130px' : '16px')};
  margin-bottom: 24px;
  transition: all 0.5s ease-out;
  @media (min-width: 600px) {
    width: 170px;
  }
  @media (min-width: 900px) {
    width: 200px;
    transform: ${({ $isHome }) =>
      $isHome ? 'translateX(90%) scale(2)' : 'translateX(50vw) translateX(-50%) scale(1)'};
  }
`;
const RedCircle = styled.div`
  border-radius: 50%;
  width: 375px;
  height: 375px;
  background-color: var(--orange);
  position: absolute;
  transform: ${({ isHome }) => (isHome ? 'translateY(-146px)' : 'translateY(-230px)')};
  left: -126px;
  top: 0;
  transition: all 0.5s ease-out;
  @media (min-width: 600px) {
    width: 425px;
    height: 425px;
  }
  @media (min-width: 900px) {
    width: 600px;
    height: 600px;
    transform: ${({ isHome }) => (isHome ? 'translateY(-266px)' : 'translateY(-380px)')};
  }
`;
const MaroonCircle = styled.div`
  border-radius: 50%;
  width: 170px;
  height: 170px;
  background-color: var(--burgundy);
  position: absolute;
  transform: ${({ isHome }) => (isHome ? 'translate(75px, 110px)' : 'translate(30px, -42px)')};
  top: 0px;
  right: 0px;
  transition: all 0.5s ease-out;
  @media (min-width: 600px) {
    transform: ${({ isHome }) => (isHome ? 'translate(55px, 180px)' : 'translate(40px, -42px)')};
  }
  @media (min-width: 900px) {
    width: 200px;
    height: 200px;
    transform: ${({ isHome }) => (isHome ? 'translate(55px, 290px)' : 'translate(40px, -42px)')};
  }
`;

const RoseCircle = styled.div`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: var(--rose);
  position: absolute;
  top: 0px;
  right: 0px;
  transition: 0.5s all ease-out;
  transform: ${({ isHome }) => (isHome ? 'translate(-400%, 275px)' : 'translate(-130px, 106px)')};
  @media (min-width: 600px) {
    transform: ${({ isHome }) => (isHome ? 'translate(-500%, 335px)' : 'translate(-130px, 130px)')};
  }
  @media (min-width: 900px) {
    transform: ${({ isHome }) => (isHome ? 'translate(-700%, 450px)' : 'translate(-180px, 130px)')};
  }
`;

const Main = styled.main`
  position: relative;
  padding: 1rem;
  max-width: 600px;
  isolation: isolate;
  margin-top: 80px;

  @media (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
  @media (min-width: 900px) {
    margin-top: 100px;
  }

  .heading {
    text-align: center;
    margin-top: 32px;
  }
  section {
    margin: 24px 0;
  }
  .subheader {
    font-family: brandon-grotesque, sans-serif;
    color: var(--gray);
    font-weight: 500;
    margin-top: 16px;
  }
`;

const Footer = styled.footer`
  margin-top: 80px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid var(--light-green);
  width: 80%;
  padding: 16px;
  p {
    text-align: center;
    font-size: 14px;
  }
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: 'Aug';
  src: url(${Augillion}) format('opentype');
}

:root {
  --blue: #204a5a;
  --light-green: #bfc49b;
  --sand: #fcf8ee;
  --rose: #7f4260;
  --burgundy: #4f1520;
  --orange: #a22424;
  --gray: #4d4d4d;
}

html,
body {
  overflow-x: hidden;
}

body {
  background-color: var(--sand);
  font-family: brandon-grotesque, sans-serif;
  position: relative;
  color: var(--gray);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Aug, brandon-grotesque, sans-serif;
    font-weight: normal;
    color: var(--blue);
    margin: 0.5rem 0;
    line-height: 1;
  }
  h1 {
    font-size: 2.25rem;
    margin-bottom: 32px;
  }
  h2 {
    font-size: 1.75rem;
  }
  p {
    color: var(--gray);
    font-size: 1.125rem;
    margin: 0.75rem 0;
    line-height: 1.4;
  }
  a {
    color: var(--rose);
  }
}
/* reset  */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


`;

export default Layout;
