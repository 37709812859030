import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import image1 from './../images/slides/image-1.jpg';
import image2 from './../images/slides/image-2.jpg';
import image3 from './../images/slides/image-3.jpg';
import image4 from './../images/slides/image-4.jpg';
import image5 from './../images/slides/image-5.jpg';
import image6 from './../images/slides/image-6.jpg';
import image7 from './../images/slides/image-7.jpg';
import image8 from './../images/slides/image-8.jpg';
import image9 from './../images/slides/image-9.jpg';
import image10 from './../images/slides/image-10.jpg';
import image11 from './../images/slides/image-11.jpg';

const ImageCircle = ({ isHome }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const slideInterval = 3000;
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
  ];

  function changeSlide() {
    const newIndex = Math.floor(Math.random() * 11);
    if (newIndex === imageIndex) {
      changeSlide();
    } else {
      setImageIndex(newIndex);
    }
  }
  useEffect(() => {
    const interval = setInterval(changeSlide, slideInterval);
    return function cleanup() {
      clearInterval(interval);
    };
  }, [imageIndex]);

  return (
    <ImageWrapper
      isHome={isHome}
      index={imageIndex}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0, y: -500 }}
      key='wrapper'
    >
      <AnimatePresence>
        <motion.img
          src={images[imageIndex]}
          alt=''
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={images[imageIndex]}
        />
      </AnimatePresence>
    </ImageWrapper>
  );
};

const ImageWrapper = styled(motion.div)`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  right: -60px;
  overflow: hidden;
  transition: all 0.5s;

  img {
    opacity: 1;
    position: absolute;
  }
  @media (min-width: 600px) {
    width: 350px;
    height: 350px;
  }
  @media (min-width: 900px) {
    width: 500px;
    height: 500px;
    top: -100px;
  }
`;

export default ImageCircle;
