// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookietable-js": () => import("./../../../src/pages/cookietable.js" /* webpackChunkName: "component---src-pages-cookietable-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rsvp-form-js": () => import("./../../../src/pages/rsvp/form.js" /* webpackChunkName: "component---src-pages-rsvp-form-js" */),
  "component---src-pages-rsvp-index-js": () => import("./../../../src/pages/rsvp/index.js" /* webpackChunkName: "component---src-pages-rsvp-index-js" */)
}

