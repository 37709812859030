import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { bool } from 'prop-types';

import { motion } from 'framer-motion';

const Menu = ({ open, setOpen }) => {
  const menuAnimation = {
    open: {
      x: 0,
      transition: {
        staggerChildren: 0.09,
        delayChidlren: 0.6,
      },
    },
    closed: {
      x: '-100%',
      transition: {
        when: 'beforeChildren',
      },
    },
  };

  const itemAnimation = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        stiffness: 1000,
        velocity: -100,
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        stiffness: 1000,
      },
    },
  };

  return (
    <MenuWrapper
      initial='closed'
      animate={open ? 'open' : 'closed'}
      variants={menuAnimation}
      transition={{ type: 'tween' }}>
      <ul>
        <motion.li variants={itemAnimation}>
          <Link to='/' onClick={open => setOpen(!open)}>
            Home
          </Link>
        </motion.li>
        <motion.li variants={itemAnimation}>
          <Link to='/details' onClick={open => setOpen(!open)}>
            Wedding Details
          </Link>
        </motion.li>
        <motion.li variants={itemAnimation}>
          <Link to='/hotel' onClick={open => setOpen(!open)}>
            Hotel/Transportation
          </Link>
        </motion.li>
        <motion.li variants={itemAnimation}>
          <Link to='/cookietable' onClick={open => setOpen(!open)}>
            Cookie Table
          </Link>
        </motion.li>
        <motion.li variants={itemAnimation}>
          <Link to='/rsvp' onClick={open => setOpen(!open)} className='button'>
            RSVP
          </Link>
        </motion.li>
      </ul>
    </MenuWrapper>
  );
};

const MenuWrapper = styled(motion.nav)`
  background-color: rgb(79, 21, 32, 0.95);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 4rem 1rem 1rem 1rem;
  ul {
    list-style: none;
    color: var(--sand);
    padding-inline-start: 0;
    li {
      color: inherit;
      font-weight: 500;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    .button {
      border: 1px solid currentColor;
      border-radius: 50px;
      display: inline-block;
      padding: 12px 50px;
      margin-top: 1rem;
      transition: background-color 0.25s ease, color 0.25s ease;
      :hover,
      :focus {
        background-color: var(--sand);
        color: var(--burgundy);
      }
    }
  }
  @media (min-width: 600px) {
    max-width: 400px;
  }
`;

Menu.propTypes = {
  open: bool.isRequired,
};
export default Menu;
